<template>
	<div class="trialroute">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="试乘试驾路线"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		
		<!-- 车型 -->
		<div class="car__model"  v-if="!info.scsj_route_id">
			<img class="car__model__logo" :src="info.car_images" mode="widthFix">
			<div class="car__model__con">{{info.model_name}} {{info.collocation_name}}</div>
			<div class="car__model__btn">刷新</div>
		</div>
		<div class="car__model_gou"  v-if="!info.scsj_route_id"></div>
		<!-- 路线 -->
		<div class="routes" v-if="!info.scsj_route_id">
			<van-swipe
				@change="handRouteEdit"
			>
				<van-swipe-item v-for="item in routes" :key="item.id">
					<img class="swipe_img" :src="item.route_image" mode="widthFix">
				</van-swipe-item>
			</van-swipe>
		</div>
		
		<div class="bj_img" v-if="info.scsj_route_id">
			<img :src="routeInfo.route_image" mode="aspectFit">
		</div>
		
		
		<!-- 体验点-->
		<div class="groups" v-if="!info.scsj_route_id">
			<div
				:class="['group', item.id == group_id ? 'group--active' : '']"
				v-for="item,key in groups"
				:key="item.id"
				@click="group_id = item.id"
			>
				<img v-if="key == 0" src="@/assets/drivroute/icon_che1.png">
				<img v-if="key == 1" src="@/assets/drivroute/icon_che2.png">
				<img v-if="key == 2" src="@/assets/drivroute/icon_che3.png">
				<div class="group__title">
					
				</div>
			</div>
		</div>
		
		
		<!-- 选项 -->
		<div class="opts" v-for="item in opts" :key="item.scsj_experience_node_id">
			<!-- <div v-if="!info.scsj_route_id" class="opts__title">
				{{item.node_name}}
			</div> -->
			<div class="opts__con">
				<div class="opt" v-for="itemContent in item.res" :key="itemContent.id">
					<div class="opt__row1">
						<div class="opt__radioed"></div>
						<div class="opt__name">
							{{itemContent.title}}
						</div>
					</div>
					<div class="opt__row2" v-if="itemContent.explain != null">
						{{ itemContent.explain }}
					</div>
					<div class="opt__row2" v-if="itemContent.star != null && itemContent.star.length != null">
						*该配置仅在{{ itemContent.star.map(v=>v.name).join(',') }}中
					</div>
				</div>
			</div>
		</div>
		
		<!-- 保存 -->
		<div style="height: 144px;"></div>
		<div class="savebtn" v-if="!info.scsj_route_id">
			<div @click="handSave">保存</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			info: {
				number: '',
			},
			route_id: 0,
			routes: [],
			swiperHeight: '300px',
			
			group_id: 0,
			groups: [],
			opts: [],
			point_ids: [],
			routeInfo: {},
			routePoints: [],
		};
	},
	mounted() {
		if (this.$route.query?.number) this.info.number = this.$route.query?.number
		this.getTrialInfo()
		this.getRouteList()
	},
	watch: {
		group_id(e) {
			this.opts.length = 0
			this.point_ids.length = 0
			this.groups.filter(v => v.id == this.group_id)[0].all_experience_point.forEach(v => {
				this.opts.push(v)
				v.res.forEach(vr => {
					this.point_ids.push(vr.id)
				})
			})
		}
	},
	methods: {
		// 选中体验点
		handPoint(e) {
			const k = this.point_ids.indexOf(e)
			if (k > -1) {
				this.point_ids.splice(k, 1)
			} else {
				this.point_ids.push(e)
			}
		},
		
		// 切换路线
		handRouteEdit(e) {
			this.route_id = this.routes[e].id
		},
		
		// 图片加载完成
		handSwiperLoad(e) {
			this.swiperHeight = `${e.detail.height}px`
		},
		
		// 保存
		async handSave(e) {
			const {data: result} = await this.$http.post('/api/scsj/edit_customer_route_point_1_5', {
				number: this.info.number,
				route_id: this.route_id,
				group_id: this.group_id,
				point_ids: this.point_ids.join(',')
			})
			if (result.code == 1) {
				this.$toast.success('保存成功')
				this.$router.go(-1)
			}
		},
		
		// 试驾详情
		async getTrialInfo(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_info_1_7', {
				number: this.info.number
			})
			if (result.code == 1) {
				this.info = { ...this.info, ...result.data.res }
				this.info.car_images = this.serverUrl(this.info.car_images)
				console.log(this.group_id,this.info?.scsj_invitation?.group_id)
				if (this.info.scsj_route_id) {
					this.getRouteInfo()
				} else {
					this.getGroupList()
					this.getRouteList()
				}
			}
		},
		
		
		// 路线详情
		async getRouteInfo(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_preview', {
				number: this.info.number
			})
			if (result.code == 1) {
				this.routeInfo = { ...this.routeInfo, ...result.data.res.route }
				this.routeInfo.route_image = this.serverUrl(this.routeInfo.route_image)
				this.routePoints.length = 0
				result.data.res.points.experience_point.forEach(v => {
					this.routePoints.push(v)
				})
				this.opts = result.data.res.points.experience_point
			}
		},
		
		// 路线列表
		async getRouteList(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_route_1_5_1', {})
			if (result.code == 1) {
				this.routes.length = 0
				this.point_ids.length = 0
				result.data.res.forEach(v => {
					v.route_image = this.serverUrl(v.route_image)
					if (this.route_id == 0) {
						this.route_id = v.id
					}
					this.routes.push(v)
				})
				if(this.info.scsj_invitation != null && this.info.scsj_invitation.group_id != null){
					this.group_id = this.info.scsj_invitation?.group_id
				}
			}
		},
		
		// 体验列表
		async getGroupList(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_customer_group_1_7_2', {
				model_id: this.info.scsj_car_model_id,
				collocation_id: this.info.scsj_car_collocation_id,
				if_cruise: this.info.if_cruise,
				store_id: this.info.scsj_store_id
			})
			if (result.code == 1) {
				this.groups.length = 0
				result.data.res.forEach(v => {
					v.images = v.images.split(',')
					v.image = this.serverUrl(v.images[0])
					if (this.group_id == 0) this.group_id = v.id
					this.groups.push(v)
				})
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.car__model_gou{
	height: 133px;
}
.car__model{
	position: fixed;
	z-index: 99;
	background: #191919;
	top: 80px;
	width: 100%;
	box-sizing: border-box;
	height: 136px;
	display: flex;
	align-items: center;
	padding: 0 40px;
	&__logo{
		width: 200px;
		margin-right: 20px;
	}
	&__con{
		font-size: 40px;
		font-family: HYQiHeiY3-45;
		overflow: hidden;
		width: 300px;
    white-space: nowrap;
    text-overflow: ellipsis;
		color: #FFFFFF;
	}
	&__btn{
		position: absolute;
		right: 40px;
		top: 37px;
		width: 104px;
		background: #FA0037;
		font-size: 32px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
		text-align: center;
		line-height: 62px;
	}
}
.groups{
	margin: 40px auto 0;
	width: 670px;
	display: flex;
	flex-wrap: nowrap;
	.group{
		flex: 1;
		position: relative;
		width: 214px;
		height: 214px;
		margin-right: 14px;
		border: 2px solid rgba(255, 255, 255, 0);
		&__title{
			position: absolute;
			left: 0;
			top: 87px;
			width: 100%;
			font-size: 40px;
			font-family: HYQiHeiY3-55;
			color: #FFFFFF;
			text-align: center;
			line-height: 40px;
		}
		img{
			width: 100%;
			height: 100%;
		}
	}
	.group:nth-child(3n){
		margin-right: 0;
	}
	.group--active{
		border: 2px solid #FA0037;
	}
}
.opts{
	margin: 40px auto 0;
	width: 670px;
	&__title{
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
		line-height: 36px;
		margin-bottom: 30px;
	}
	&__con{
		background: #222;
		padding: 60px 38px 160px;
	}
	.opt{
		width: 100%;
		margin-bottom: 70px;
		&__row1{
			display: flex;
		}
		&__radio{
			width: 32px;
			height: 32px;
			border: 1px solid #FFFFFF;
			border-radius: 50%;
		}
		&__radioed{
			width: 12px;
			height: 12px;
			margin-top: 10px;
			border-radius: 50%;
			background: #F3C846;
		}
		&__name{
			width: 550px;
			margin-left: 20px;
			font-size: 36px;
			font-family: HYQiHeiY3-45;
			color: #FFFFFF;
			line-height: 32px;
		}
		&__row2{
			margin-top: 30px;
			padding-left: 52px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #919191;
			line-height: 40px;
		}
	}
	.opt:last-child{
		margin-bottom: 0;
	}
}
.savebtn{
	background: #191919;
	position: fixed;
	bottom: 0;
	box-sizing: border-box;
	padding: 22px 40px;
	padding-bottom: calc(constant(safe-area-inset-bottom) + 22px);
	padding-bottom: calc(env(safe-area-inset-bottom) + 22px);
	width: 100%;
	div{
		width: 100%;
		background: #FA0037;
		text-align: center;
		line-height: 92px;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
	}
}

.bj_img{
	width: 750px; 
	height: 559px;
	img{
		width: 750px; 
		height: 559px;
	}
}
.swipe_img{
	width: 750px;
	height: 559px;
}
</style>
